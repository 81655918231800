<template>
  <div class="terms-container">
    <h1>TestAdmit Terms & Policies</h1>
    <p class="last-updated">Last Updated: February 25, 2025</p>

    <h2>1. Description of Services</h2>
    <p>
      TestAdmit provides unofficial practice resources to help students prepare
      for the TOEFL exam. Our platform allows users to practice test questions
      and improve their skills. We do not administer the official TOEFL exam,
      nor are we affiliated with or endorsed by ETS, the organization that
      distributes the TOEFL test. Those who wish to register for the official
      TOEFL exam should do so directly on the ETS website
    </p>
    <h2>2. Purchase Currency</h2>
    <p>
      All prices on TestAdmit are displayed in <b>USD ($)</b>. Customers are
      responsible for checking exchange rates and any fees imposed by their
      payment provider when making purchases in a foreign currency.
    </p>
    <h2>3. Customer Service Contact Information</h2>
    <p>
      For any inquiries, assistance, or concerns, please contact us at
      support@testadmit.com. We strive to respond to all inquiries within 24
      business hours.
    </p>
    <h2>4. Fulfillment Policies</h2>
    <h3>4.1 Refund Policy</h3>
    <p>
      TestAdmit offers a free plan for all users to try the features available
      in our Pro plan. As a result, we do not provide refunds once a plan has
      been purchased. Subscriptions are automatically renewed upon purchase by
      default. If your subscription remains active after the last day of your
      current billing cycle, you will be charged for the next cycle. If you
      cancel your subscription before the renewal date, you will not be charged
      again after your current subscription expires. You can view and manage
      your subscription in the
      <router-link
        :to="{
          name: 'ToeflPlans'
        }"
        target="_blank"
      >
        <b>Plans</b></router-link
      >
      page.
    </p>
    <h3>4.2 Delivery Policy</h3>
    <p>
      Since our services are digital, no physical goods will be shipped.
      Immediately after a successful purchase, customers will receive access to
      the services they purchased on TestAdmit's website.
    </p>
    <h2>5. Legal & Export Restrictions</h2>
    <p>
      TestAdmit complies with all applicable laws and regulations. You are
      responsible for ensuring that they meet local requirements and eligibility
      criteria when you sign up for the TOEFL exam.
    </p>
    <h2>6. Privacy Policy</h2>
    <p>
      TestAdmit collects and processes user information to provide and improve
      our services. This includes account details, payment information, and
      usage data. We may use third-party services, such as Stripe for payments
      and Google Analytics for tracking, which have their own privacy policies.
      Our website also uses cookies to enhance functionality, analyze site
      traffic, and improve user experience; users can manage cookie preferences
      in their browser settings. We prioritize data security and implement
      measures to protect your personal information from unauthorized access or
      misuse. TestAdmit does not sell, trade, or rent user data, and we only
      share information when required by law or when necessary for service
      providers to fulfill their obligations. By using TestAdmit, you agree to
      this Privacy Policy, which may be updated from time to time.
    </p>
    <h2>7. Promotions & Discounts</h2>
    <p>
      Any promotional offers or discounts will be explicitly stated on our
      website, including terms and conditions regarding eligibility, duration,
      and redemption process.
    </p>
    <h2>8. Website Security & Payment Information</h2>
    <p>
      We prioritize the security of your personal and payment information.
      TestAdmit complies with PCI DSS (Payment Card Industry Data Security
      Standard) and uses SSL encryption (HTTPS) to protect transactions. Your
      payment details are securely processed through our payment provider, and
      we do not store any credit card information on our servers.
    </p>
    <h2>9. Accepted Payment Methods</h2>
    <p>
      We accept the following payment methods: Visa, Mastercard, American
      Express.
    </p>
    <p class="footer-note">
      By using TestAdmit, you agree to these terms. For any questions, feel free
      to contact us at
      <a href="mailto:support@testadmit.com">support@testadmit.com</a>
    </p>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import ComponentExample from "@/components/_ComponentExample";

export default {
  metaInfo() {
    return {
      title: "Terms - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      sections: []
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.8;
  color: #4a4a4a;
}

h1 {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 15px;
  font-size: 2.5em;
  font-weight: 600;
}

.last-updated {
  text-align: center;
  color: #888;
  margin-bottom: 50px;
  font-size: 0.9em;
}

h2 {
  color: #1a1a1a;
  margin: 35px 0 20px;
  font-size: 1.8em;
  font-weight: 500;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

h3 {
  color: #2a2a2a;
  margin: 25px 0 15px;
  font-size: 1.4em;
  font-weight: 500;
}

p {
  margin-bottom: 1.2em;
}

a {
  color: #2196f3;
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: #1976d2;
  text-decoration: none;
}

.footer-note {
  margin-top: 50px;
  padding-top: 25px;
  border-top: 2px solid #f0f0f0;
  color: #888;
  font-size: 0.95em;
  text-align: center;
}

@media (max-width: 768px) {
  .terms-container {
    padding: 20px 15px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.2em;
  }
}
</style>
